import { useEffect } from 'react';

const Custom404 = () => {
  // Redirect to home page
  useEffect(() => {
    window.location.href = '/';
  }, []);

  return (<div />);
};

export default Custom404;
